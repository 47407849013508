import React from "react";
import "./style.css";

import { useHistory } from "react-router-dom";

const Page404 = () => {
    const history = useHistory();

    return (
        <div className="page-404-container">
            <pre className="page-404-titulo">Página não encontrada</pre>
            <button className="page-404-home" onClick={() => history.push("/bloqueto")}>Voltar para home</button>
        </div>
    )
}

export default Page404;