import React, { useState, useContext } from "react";
import "./style.css";

import { Link } from 'react-router-dom';
import { AuthContext } from "../../context/Auth";
import { Sidebar, Menu, MenuItem } from 'react-pro-sidebar';
import { BiSolidBarcode, BiSolidWrench } from "react-icons/bi";
import { BsPersonCircle } from "react-icons/bs";
import { FaLock } from "react-icons/fa6";
import { Oval } from  "react-loader-spinner";
import { ButtonOK } from "../Button";
import { hostNameDSI, toastSuccess } from "../../library";
import TelaGeral from "../TelaGeral";
import Header from "../Header";
import api, { MENSAGEM_ERRO_HTTP } from "../../api";

const MenuSideBar = ({ children }) => {
    const [loading, setLoading] = useState(false);
    const [collapsed, setCollapsed] = useState(false);
    const [toggled, setToggled] = useState(false);
    const [broken, setBroken] = useState(false);
    const [changePassword, setChangePassword] = useState(false);
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [errorPassword, setErrorPassword] = useState("");
    const [errorConfirmPassword, setErrorConfirmPassword] = useState("");
    const userAbrev = localStorage.getItem("userAbrev");
    const { handleLogout } = useContext(AuthContext);

    const menuItemStyles = {
        button: ({ level, active, disabled }) => {
            if (level === 0) {
                return {
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    color: disabled ? "#eee" : "black",
                    backgroundColor: active ? "rgb(192, 192, 192, 0.5); !important" : "white",
                    "&:hover": {
                        display: "flex",
                        backgroundColor: "rgb(192, 192, 192, 0.6); !important",
                    },
                };
            }
        },
        icon: ({active}) => {
            return {
                color: active ? "#b72126" : "silver"
            }
        },
        label: ({active}) => {
            return {
                fontSize: "14px",
                fontWeight: active ? "bold" : "300"
            }
        }
    };

    async function handleChangePassword() {
        let response;
        let validou = true;
        setErrorPassword("");
        setErrorConfirmPassword("");

        if (password.length < 8) {
            setErrorPassword("Mínimo 8 caracteres");
            validou = false;
        }
        
        if (confirmPassword.length < 8) {
            setErrorConfirmPassword("Mínimo 8 caracteres");
            validou = false;            
        }

        if (validou && password !== confirmPassword) {
            validou = false;
            setErrorPassword("Informe a mesma senha nos 2 campos");
        }

        if (! validou)
            return false;

        try {
            setLoading(true);
            
            response = await (await api.post(
                "/altera-senha",
                {
                    password: password
                }
            )).data;        
            
            if (response?.error) {
                setErrorPassword(response.message);
            } else {
                handleLogout();
                toastSuccess(response.message, 3000);
            }            

        } catch {
            MENSAGEM_ERRO_HTTP()
        } finally {
            setLoading(false);
        }
    }

    const size = 24;

    return (
        <div style={{ display: 'flex', height: '100%'}}>
            <TelaGeral
                className="modal-shift"
                isOpen={changePassword}
                onRequestClose={() => setChangePassword(false)}
                titulo="Alterar senha"
            >
                <div className="menu-password-content">
                    <span className="menu-password-alert">{errorPassword}</span>
                    <div className="menu-password-container-input">
                        <FaLock size={14} color="gray" />
                        <input 
                            className="menu-password-input"   
                            placeholder="Nova senha"
                            maxLength={10}
                            value={password}
                            onChange={e => setPassword(e.target.value.toUpperCase())}
                        />
                    </div>

                    <span className="menu-password-alert">{errorConfirmPassword}</span>
                    <div className="menu-password-container-input">
                        <FaLock size={14} color="gray" />
                        <input 
                            className="menu-password-input"   
                            placeholder="Confirmação da nova senha"
                            maxLength={10}
                            value={confirmPassword}
                            onChange={e => setConfirmPassword(e.target.value.toUpperCase())}
                        />
                    </div>

                    <div className="menu-password-bottom">
                    {
                        loading
                        ? <Oval
                            height={32}
                            width={32}
                            color="#b72126"
                            wrapperStyle={{}}
                            wrapperClass=""
                            visible={true}
                            ariaLabel='oval-loading'
                            secondaryColor="#b72126"
                            strokeWidth={2}
                            strokeWidthSecondary={4}/>
                        : <ButtonOK title="Alterar" onClick={handleChangePassword}/>       
                    }
                    </div>                    
                </div>
            </TelaGeral>

            <div style={{ display: 'flex', flexDirection: 'column', height: "100%" }}>
                <Sidebar 
                    collapsed={collapsed}
                    toggled={toggled}
                    onBackdropClick={() => setToggled(false)}
                    onBreakPoint={setBroken}
                    breakPoint="md"
                    backgroundColor="white"
                    width="230px"
                >
                    <div className="menu-header">
                        <div className="menu-header-avatar">
                            <BsPersonCircle size={36} color="slategray" />
                        </div>

                        <div className="menu-header-user"> 
                            <span className="menu-header-user-name">{userAbrev}</span>
                            <div style={{display: "flex", width: "100%", justifyContent: "space-between"}}>
                                <span className="menu-header-user-password" onClick={() => setChangePassword(true)} >Alterar senha</span>
                                <span className="menu-header-user-password" onClick={handleLogout}>Sair</span>
                            </div>
                        </div>

                        <div className="menu-header-options">
                        </div>
                    </div>

                    <div style={{ display: 'flex', flexDirection: 'column'}}>
                        <Menu menuItemStyles={menuItemStyles}>
                            <MenuItem active={window.location.pathname === "/bloqueto" || window.location.pathname === "/"} component={<Link to="/bloqueto" />} icon={<BiSolidBarcode size={size} />}>Boletos</MenuItem>

                            {!hostNameDSI() ? <MenuItem active={window.location.pathname === "/ordem-servico"} component={<Link to="/ordem-servico" />} icon={<BiSolidWrench size={size} />}>Ordens de Serviço</MenuItem> : null}           
                        </Menu>
                    </div>  

                <div className="menu-bottom">
                    <img alt="logo" src={hostNameDSI() ? require("../../assets/img/logo_dsi.png") : require("../../assets/img/logo_defferrari.png")} style={{height: 75, objectFit: "cover"}} />
                </div>                                                                               
                </Sidebar>                                              
            </div>  

            <main className="menu-container">
                <Header  menuToggled={() => setToggled(true)} menuBroken={broken} />
                {children}
            </main>                                  
        </div>
    );
};

export default MenuSideBar;