import React, {useState, useEffect, useContext, useRef} from "react";
import "./style.css";
import Package from "../../../package.json";

import { AuthContext } from "../../context/Auth";
import { Oval } from  "react-loader-spinner";
import { FaUserLarge, FaLock } from "react-icons/fa6";
import { MdOutlineAlternateEmail } from "react-icons/md";
import { ButtonOK } from "../../components/Button";
import { hostNameDSI, toastSuccess, validaEmail } from "../../library";
import TelaGeral from "../../components/TelaGeral";
import api, { MENSAGEM_ERRO_HTTP } from "../../api";

function Login() {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState("");
    const [errorEmail, setErrorEmail] = useState("");
    const [errorUser, setErrorUser] = useState("");
    const [user, setUser] = useState("");
    const [password, setPassword] = useState("");
    const [showForgotPassword, setShowForgotPassword] = useState(false);
    const [email, setEmail] = useState("");

    const userRef = useRef(null);
    const passwordRef = useRef(null);

    const { handleLogin } = useContext(AuthContext);

    useEffect(() => {
        let _user = localStorage.getItem("user");
        if (_user) {
            setUser(_user);
            passwordRef.current.focus();
        } else 
            userRef.current.focus();    
    }, []);

    const handlevalidation = async () => {
        setError("");

        if (! user) {
            setError("Informe o código");
            return;
        } else if (! password) {
            setError("Informe a senha");
            return;
        }

        setLoading(true);

        let response = await handleLogin(user, password, "");

        setLoading(false);            

        if (response && response.message)
            setError(response.message);
    };

    function handleEnter(e) {
        if (e.key.toLowerCase() === "enter") 
            if (e.target.id === "input_usuario")
                passwordRef.current.focus();
            else if (e.target.id === "input_senha")
                handlevalidation();
    }

    async function handleForgotPassword() {
        let validated = true;
        let response;

        setErrorUser("");
        setErrorEmail("");

        if (! user) {
            validated = false;
            setErrorUser("Campo obrigatório");
        }

        if (! email) {
            validated = false;
            setErrorEmail("Campo obrigatório");
        } else if (! validaEmail(email)) {
            validated = false;
            setErrorEmail("Informe um e-mail válido");
        }

        if (! validated)
            return false;  
        
        try {
            setLoading(true);

            response = await (await api.post(
                "/recupera-senha",
                {
                    user: user ? user : "",
                    email: email ? email : "",
                    host: window?.location?.hostname.toLowerCase(),
                    dash: false
                }
            )).data;        
            
            if (response?.error) {
                setErrorUser(response.message);
            } else {
                setEmail("");
                setPassword("");
                setShowForgotPassword(false);
                toastSuccess(response.message, 3000);
            }

            console.log(response);
        } catch  {
            MENSAGEM_ERRO_HTTP();
        } finally {
            setLoading(false);
        }
    }

    return (
        <div className="container-login">
            <TelaGeral
                className="modal-shift-login-senha"
                isOpen={showForgotPassword}
                onRequestClose={() => setShowForgotPassword(false)}
                titulo="Recuperar senha"
            >
                <div className="forgot-password-content">
                    <span className="span-alert">{errorUser}</span>

                    <div className="container-input">
                        <FaUserLarge size={14} color="gray" />
                        <input 
                            className="input"   
                            id="input_usuario"
                            placeholder="CPF ou CNPJ"
                            ref={userRef}
                            maxLength={18}
                            value={user}
                            onChange={e => setUser(e.target.value.toUpperCase())}
                        />
                    </div>

                    <span className="span-alert">{errorEmail}</span>

                    <div className="container-input">
                        <MdOutlineAlternateEmail size={17} color="gray" />
                        <input 
                            className="input"
                            placeholder="E-mail"
                            type="email"
                            value={email}
                            onChange={e => setEmail(e.target.value.toLocaleLowerCase())}
                        />
                    </div>     

                    <div className="forgot-password-bottom">
                    {
                        loading
                        ? <Oval
                            height={32}
                            width={32}
                            color="#b72126"
                            wrapperStyle={{}}
                            wrapperClass=""
                            visible={true}
                            ariaLabel='oval-loading'
                            secondaryColor="#b72126"
                            strokeWidth={2}
                            strokeWidthSecondary={4}/>
                        : <ButtonOK title="Recuperar" onClick={handleForgotPassword}/>       
                    }
                    </div>
                </div>        
            </TelaGeral>

            <div className="login">
                <img alt="logo" src={hostNameDSI() ? require("../../assets/img/logo_dsi.png") : require("../../assets/img/logo_defferrari.png")} className="logo" />

                <span className="span-alert">{error}</span>

                <div className="container-input" style={{width: "100%"}}>
                    <FaUserLarge size={14} color="gray" />
                    <input 
                        className="input"   
                        id="input_usuario"
                        placeholder="CPF ou CNPJ"
                        ref={userRef}
                        maxLength={18}
                        value={user}
                        onChange={e => setUser(e.target.value)}
                        onKeyDown={handleEnter}
                    />
                </div>

                <div className="container-input" style={{width: "100%"}}>
                    <FaLock size={14} color="gray" />
                    <input 
                        id="input_senha"
                        ref={passwordRef}
                        type="password"
                        className="input"
                        placeholder="Senha"
                        value={password}
                        onChange={e => setPassword(e.target.value)}
                        onKeyDown={handleEnter}
                    />
                </div>   

                <span className="lost-password" onClick={() => setShowForgotPassword(true)}>Esqueceu sua senha?</span> 

                {loading ?
                    <Oval
                        height={32}
                        width={32}
                        color="#b72126"
                        wrapperStyle={{}}
                        wrapperClass=""
                        visible={true}
                        ariaLabel='oval-loading'
                        secondaryColor="#b72126"
                        strokeWidth={2}
                        strokeWidthSecondary={4}

                    />
                : <ButtonOK title="Entrar" onClick={handlevalidation}/>            
                }

                <span className="version">Versão {Package.version}</span>
            </div>
        </div>
    );
};

export default Login;