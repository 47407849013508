import React, { useContext } from "react";
import { Switch, Route, Redirect, BrowserRouter } from "react-router-dom";
import { AuthContext, AuthProvider } from "../context/Auth";
import { hostNameDSI } from "../library";

import Page404 from "./404";
import Login from "./Login";
import Bloqueto from "./Bloqueto";
import OrdemServico from "./OrdemServico";

function CustomRoute({ isPrivate, ...rest }) {
    const { loading, authenticated } = useContext(AuthContext);
  
    if (loading) {
      return null
    }

    if (isPrivate && !authenticated) {
      return <Redirect to="/login" />
    }
  
    return <Route {...rest} />;
  }

export default function Routes() {
    return (
      <BrowserRouter>
        <AuthProvider>
          <Switch>
            <CustomRoute isPrivate exact path="/" component={Bloqueto} />
            <CustomRoute exact path="/login" component={Login} />
            <CustomRoute isPrivate exact path="/bloqueto" component={Bloqueto} />

            {!hostNameDSI() ? <CustomRoute isPrivate exact path="/ordem-servico" component={OrdemServico} /> : null}

            <CustomRoute path="*" component={Page404} />            
          </Switch>
        </AuthProvider>
      </BrowserRouter>
    );
};